ul > li {
    text-align: left;
}
.logo-lg{
    padding: 0 1.5rem;
    display: block;
    margin-top: 10px;
}
.logo-lg img{
   
}