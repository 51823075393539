.react-rte-demo {
    min-height: 240px;
    height: 240px;
    overflow-x: auto;
    background: #f9f9f9;
    border: 0;
}
.demo-toolbar {
    display: none;
}

.txt-uppercase {
    text-transform: uppercase;
}