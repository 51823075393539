.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.content-fit {
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
}

.fileStatusDiv {
  margin: 5px;
  padding: 5px;
  margin-left: 0;
  padding-left: 0;
}

.fileStatus {
  color: red;
  font-weight: 500;
}

.fileStatusSuccess {
  color: green;
  font-weight: 500;
}

.logo-form {
  background: #fff;
  padding: 10px 20px;
}

.switch input {
  position: absolute;
  opacity: 0;
}

.switch {
  display: inline-block;
  font-size: 20px;
  /* 1 */
  height: 1em;
  width: 2em;
  background: #BDB9A6;
  border-radius: 1em;
}

.switch div {
  height: 1em;
  width: 1em;
  border-radius: 1em;
  background: #FFF;
  box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  transition: all 300ms;
}

.switch input:checked+div {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.list_action {
  display: flex;
  justify-content: center;
}


.switch>input[type="checkbox"]:checked {
  background: green !important;
}

.modal-header {
  display: block;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.main {
  /* width: 40%;
  margin: 10px; */
}

/* Bootstrap 4 text input with search icon */

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

a.a-href {
  color: #007bff !important;
  text-decoration: none;
  background-color: transparent;
}

a.a-href:hover {
  color: #0056b3 !important;
  text-decoration: underline !important;
}

.p-relative {
  position: relative;
}

.btn-search-align {
  position: absolute;
  right: 0;
  z-index: 5;
  border-radius: 50%;
  background: transparent;
  border: 0;
  color: #BBBBBB;
  top: 50%;
    transform: translate(-50%, -50%);
}
.form-control:focus{
  border-color: none;
  box-shadow: none;
}

.required:after {
  content:" *";
  color: red;
}