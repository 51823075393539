form {
    text-align: left;
}

.img-logo {
    width: 80%;
}

.mb-20 {
    margin-bottom: 20px;
}

.box-shadow {
    transform: scale(1);
    /* box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25); */
}
.fxt-bg-img{
    background-image: url('/public/assets/images/slider.png');
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
    border-radius: 10px;
}
.login_bg{
    height: 100vh;
   
}
.login-content .form-group .form-control{
    background: #EDF5FF;
    border: 0;
    height: 55px;
    padding: 0.375rem 1rem;

}
.login-content{
    height: 96vh;
    display: flex;
    align-items: center;
    width: 100%;
}
.mb-10{
    margin-bottom: 2rem;
}
input[type=checkbox]{
    position: relative;
    /* border: 2px solid #000; */
    border-radius: 2px;
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: 0 0.6em 0 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    background: #D9D9D9;
}
input[type=checkbox]:before {
 

 



}
input[type=checkbox]:checked {
    background-color: #3278FF;
    opacity: 1;
}
input[type=checkbox]:checked::before{
    content: '';
    position: absolute;
    width: 4px;
    height: 10px;
    border: solid #FFF;
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
    right: 50%;
    top: 50%;
}
.loginSwipers .swiper-pagination{
text-align: right;
padding-right: 20px;
}
.loginSwipers .swiper-pagination .swiper-pagination-bullet{
    width: 20px;
    display: inline-block;
    height: 3px;
    background: #fff;
    border-radius: 10px;
}
.loginSwipers .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active{
    width: 40px;
}